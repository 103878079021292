<template>
  <div class="container-bg-textree">
    <div class="inner">
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped>
.container-bg-textree {
  position: fixed;
  top: 0;
  bottom: 0;
  right: calc(1600px + (100% - 1600px) / 2);
  width: calc((100% - 1600px) / 2);
  max-width: 160px;
  height: 100%;
  display: flex;
  align-items: flex-end;
}
.inner {
  position: absolute;
  top: 25%;
  left: -90%;
  background-image: url('/assets/images/TEXTree-bg.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  width: 200%;
  height: 200%;
}

@media(max-width: 1440px) {
  .container-bg-textree {
    display: none;
  }
}
</style>
